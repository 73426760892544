.university.video_title {
    min-height: auto;
    padding-bottom: 90px;
    padding-top: 25px;
}
.university_title {
    font-family: "Karla", sans-serif;
    font-size: 40px;
    font-weight: 800;
    color: #2b2d33;
    line-height: 40px;
    max-height: 80px;
}

.breadcrumbs_container {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 10%;
    width: 100%;
    padding-bottom: 13px;
    padding-left: 3px;
}
.video-tital h4{
    font-size: 1.4rem;
}
.video-row {
    width: 100%;
    background: #f5f5f5;
    padding-top: 60px;
    padding-bottom: 100px;
}
.confused_wrap {
    background: #e6eaed;
    padding-top: 20px;
    margin-top: 80px;
}
.advice_wrap #e_loan a {
    height: 66px;
    line-height: 34px;
    word-break: break-word;
    padding: 10px 22px;
    width: auto;
    transition: ease-in-out 0.8s;
}
#e_loan a {
    height: 60px;
    word-break: break-word;
    line-height: 28px;
    color: #fff;
    background: #0d6efd;
    font-size: 1.3rem;
    padding: 10px;
    text-align: center;
    border-radius: 13px;
    font-weight: 500;
    letter-spacing: 1px;
    width: 100%;
    border: 5px solid #fff;
    transition: ease-in-out 0.8s;
}
@media only screen and (max-width: 480px) {
    .advice_wrap #e_loan a {
        height: 100px;
    }
}